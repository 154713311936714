import React, { useEffect, useState } from 'react';
import CardComponent from './components/CardComponent';
import { Row, Col } from 'antd';
import cardsData from './data/cards.json';
import './App.css'; // 引入样式文件

const App = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(cardsData);
  }, []);

  const categories = [...new Set(cards.map(card => card.category))]; // 获取唯一分类

  return (
    <div style={{ padding: '20px' }}>
      <div className="banner">
        <h1>家庭系统导航页</h1>
      </div>
      {categories.map(category => (
        <div key={category} style={{ marginBottom: '40px' }}>
          <h2>{category}</h2>
          <hr style={{ margin: '10px 0' }} />
          <Row gutter={[16, 16]}> {/* 设置左右间距为16px，上下间距为16px */}
            {cards
              .filter(card => card.category === category)
              .map((card, index) => (
                <Col xs={24} sm={12} md={8} lg={4}  key={index}>
                  <CardComponent 
                    title={card.title} 
                    description={card.description} 
                    link={card.link} 
                    icon={card.icon} 
                  />
                </Col>
              ))}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default App;
