import React from 'react';
import { Card } from 'antd';
import './CardComponent.css'; // 引入样式文件

const CardComponent = ({ title, description, link, icon }) => {
    return (
        <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={icon} alt={title} style={{ width: '20px', marginRight: '8px' }} />
            {title}
          </div>
        }
        className="custom-card"
        onClick={() => window.open(link, '_blank')}
      >
        <p>{description}</p>
      </Card>
      );
    };

export default CardComponent;
